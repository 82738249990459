import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MlcSharedUiModule } from '@mlc/shared/mlc-shared-ui.module';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';

import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

const firebaseDb = environment.db;
const dbConfig = firebaseDb.providerAdmin ? { ...firebaseDb.config, ...firebaseDb.providerAdmin } : firebaseDb.config;
@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MlcSharedUiModule,
    HighlightModule,
    AngularFireModule.initializeApp(dbConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          json: () => import('highlight.js/lib/languages/json'),
          handlebars: () => import('highlight.js/lib/languages/handlebars'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
