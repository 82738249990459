import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from './../app.service';
import { AppVars } from './../app.vars';
import { User } from './../auth/auth.user';
import { AuthService } from './../auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mlc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userAccountSubscription: Subscription;
  dateValue: Date = new Date();
  email: string;
  password: string;
  submitted = false;
  showProgress = false;
  ackMessage = '';
  errorMessage = '';
  verificationFailed = false;

  dashboardPath = '/admin';

  // TODO: Move all the 'User Feedback / Error Messages' into bundle for i18N implementation
  constructor(private authService: AuthService, private _as: AppState, private router: Router) {}

  ngOnInit() {
    // To avoid permission denied error post logout authService.user should be unsubscribed - ngOnDestroy()
    this.userAccountSubscription = this.authService.user.subscribe((user: User) => {
      console.log('[LoginComponent]: init() user = ', user, new Date());
      if (user) {
        if (user.emailVerified) {
          this._as.set(AppVars.USER_ACCOUNT, user);
          this._as.set(AppVars.ORG_CODE, user.orgCode);
          const nextRoute = this.dashboardPath;
          console.log('[LoginComponent]: init() next route path = ', nextRoute);
          this.router.navigate([nextRoute]).then(ok => console.log('Next Route: ', nextRoute, ' OK: ', ok));
        } else {
          this.verificationFailed = true;
          this.errorMessage = 'Your registered email is not yet verified!';
          this.showProgress = false;
          this.submitted = false;
        }
      }
    });
  }

  reset(): void {
    this.email = '';
    this.password = '';
    this.ackMessage = '';
    this.errorMessage = '';
    this.submitted = false;
    this.showProgress = false;
    this.verificationFailed = false;
  }

  submit(): void {
    if (!this.email || !this.password) {
      return;
    }
    this.showProgress = true;
    console.log('Email: ', this.email);
    this.submitted = true;
    this.errorMessage = '';
    this.ackMessage = '';

    this.authService.login(this.email, this.password).catch(err => {
      console.log('[LoginComponent] login() catch() now = ', new Date(), err);
      this.errorMessage = 'Cannot login, please check your credentials or network connection.';
      console.log('Login error: ', err);
      this.showProgress = false;
      this.submitted = false;
    });
  }


  /**
   * Tear down and unsubscribe to the User Account
   */
  ngOnDestroy() {
    if (this.userAccountSubscription) {
      this.userAccountSubscription.unsubscribe();
    }
    // Before destroying the Login Screen, check if user logged in push an audit log
    if (this._as.get(AppVars.USER_ACCOUNT)) {
      console.log('[LoginComponent] onDestroy() before calling auditLog()');
      this.authService.auditLog();
    }
  }
}
