<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px" class="auth-box">
  <!--Header-->
  <div fxFlex="30" fxLayoutAlign="center center" class="header">
    <!-- <img sizes="240px" src="./../../assets/images/MLC-logo2x.png" srcset="./../../assets/images/MLC-logo2x-p-500.png 500w, ../../assets/images/MLC-logo2x.png 542w"
      width="240" /> -->
    <!-- <img src="/assets/images/am_logo_full_v.svg" alt="OrganizeMED" width="240" /> -->
    <img src="/assets/images/om_logo_full_h.svg" alt="OrganizeMED" width="340" />
  </div>

  <!-- Sign In / Login card  -->
  <mat-card fxLayout="column" fxLayoutGap="12px" class="login-card">
    <!-- <mat-card-header fxLayout="row"> -->
    <div fxLayout="row" fxLayoutAlign="center center">
      <h3 class="card-title">for Administrators only</h3>
    </div>
    <div class="card-title">Sign In</div>
    <!-- </mat-card-header> -->
    <form id="loginForm">
      <mat-card-content fxLayout="column">
        <div fxFlex="40">
          <mat-form-field class="full-width">
            <input
              matInput
              type="email"
              autocomplete="email"
              name="email"
              placeholder="Email Address"
              [(ngModel)]="email"
              id="emailInput"
            />
            <mat-icon matPrefix>mail_outline</mat-icon>
          </mat-form-field>
        </div>
        <div fxFlex="40">
          <mat-form-field class="full-width">
            <input
              matInput
              type="password"
              autocomplete="current-password"
              name="password"
              placeholder="Password"
              [(ngModel)]="password"
              (keyup.enter)="submit()"
              id="passwordInput"
            />
            <mat-icon matPrefix>lock_outline</mat-icon>
          </mat-form-field>
        </div>
        <div fxFlex="20">
          <p *ngIf="ackMessage || errorMessage">
            {{ ackMessage }}
            <mat-error> {{ errorMessage }}</mat-error>
          </p>
        </div>
      </mat-card-content>
    </form>

    <mat-card-actions fxLayoutAlign="end center">
      <button mat-stroked-button color="primary" (click)="submit()">
        <span *ngIf="!showProgress; else spinner">SIGN IN</span>
        <ng-template #spinner>
          <mat-spinner color="primary" diameter="20" strokeWidth="2" style="margin: 8px"></mat-spinner>
        </ng-template>
      </button>
      <button mat-button (click)="reset()">RESET</button>
    </mat-card-actions>
  </mat-card>

  <div fxFlex></div>
  <!--Footer-->
  <footer fxFlex="20" class="footer">
    <div class="copyright">© {{ dateValue | date: 'yyyy' }} AccidentMATE by Pranava Inc. All rights reserved.</div>
  </footer>
</div>
