import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppState } from './../app.service';
import { AppVars } from './../app.vars';
import { User } from './auth.user';

import 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * User object from the /provider-users/${uid} node as an observable
   * AuthService.user is an observable available for other components to subscribe
   * When the user logs out, this would stream 'null' output
   */
  user: Observable<User | null>;

  // private fbAuth = firebase.auth();

  private userAccount: {
    uid: string;
    emailVerified: boolean;
    displayName: string;
    photoURL: string;
    lastLogin?: Date;
  };
  private usersPath = 'provider-users';
  private orgRoot = 'provider-org';

  /**
   * AuthService maps the logged in user with the User Node under /provider-users
   */
  constructor(private _as: AppState, private ngFireAuth: AngularFireAuth, private ngFireDb: AngularFireDatabase, private router: Router) {
    this.user = this.ngFireAuth.authState.pipe(
      switchMap((currentUser) => {
        if (currentUser) {
          // this.userAccount = {
          //   uid: currentUser.uid,
          //   emailVerified: currentUser.emailVerified,
          //   displayName: currentUser.displayName,
          //   photoURL: currentUser.photoURL,
          // };
          // this._as.set(AppVars.USER_ACCOUNT, this.userAccount);
          // console.log('[AuthService] authState: User Account is set to AppState.', this.userAccount);
          return this.ngFireDb
            .object<User>(`${this.usersPath}/${currentUser.uid}`)
            .valueChanges()
            .pipe(
              map((user) => {
                this.userAccount = {
                  uid: currentUser.uid,
                  emailVerified: currentUser.emailVerified,
                  displayName: currentUser.displayName,
                  photoURL: currentUser.photoURL,
                  ...user,
                };
                // console.log('[AuthService] db: User Account: ', this.userAccount);
                return this.userAccount;
              })
            );
          // TODO: Add user roles into the userAccount object obtained from usersPath/uid - use map post valueChanges()
        } else {
          // this._as.set(AppVars.USER_ACCOUNT, null);
          // this._as.set(AppVars.ORG_CODE, null);
          // this.router.navigate(['/']); // Now force the user to the login screen
          return observableOf(null);
        }
      })
    );
    this.user.subscribe((user) => {
      if (user) {
        this._as.set(AppVars.USER_ACCOUNT, user);
        this._as.set(AppVars.ORG_CODE, user.orgCode);
        console.log('[AuthService] User Subscribed: User Account: ', user);
        console.log('[AuthService] AppService get() OrgCode:', this._as.get(AppVars.ORG_CODE));
      } else {
        this._as.set(AppVars.USER_ACCOUNT, null);
        this._as.set(AppVars.ORG_CODE, null);
        this.router.navigate(['/']); // Now force the user to the login screen
      }
    });
  }

  /**
   * Logout the currently logged in user and remove the user account from the App State
   */
  public logout(): void {
    // TODO: This will force logout any refresh - make this smooth by refactoring
    if (!this.userAccount || !this.userAccount.uid) {
      this.signout();
    }
    const now = new Date().toISOString();
    const uid = this.userAccount?.uid;
    this._as.set(AppVars.USER_ACCOUNT, null);
    this._as.set(AppVars.ORG_CODE, null);
    this.ngFireDb
      .object(`${this.usersPath}/${uid}/logs`)
      .update({ logged_out: now })
      .then(() => {
        this.ngFireAuth
          // this.fbAuth
          .signOut()
          .then(() => {
            console.log(`[AuditLog] logout(): Logout Finished!`);
            this.router.navigate(['/']);
          })
          .catch((err) => {
            console.log('AuthService: Error while logging out - ', err.message);
          });
        console.log(`[AuthService] log_out update to provider-users/${uid}/logout: ${now}`);
      });
  }

  /**
   * Get the provider user account's orgCode approval details
   * @param uid Optional UID for the user, default logged in UID is used
   */
  public getUserAccount(uid?: string): Observable<any> {
    // console.log('[AuthService] getUserAccount(): parameter uid = ', uid);
    const uidForPath = uid ? uid : this.userAccount.uid;
    const userNodePath = `${this.usersPath}/${uidForPath}`;
    console.log('[AuthService] getUserAccount(): userNodePath = ', userNodePath);
    if (!uidForPath) {
      throw new Error('[AuthService] - missing UID to get User Account!');
    }
    return this.ngFireDb.object(userNodePath).valueChanges();
  }

  /**
   * Get the Organization metadata node
   * @param orgCode Organization Code to get the Org Data
   */
  public getOrgData(orgCode: string): Observable<any> {
    const orgPath = `${this.orgRoot}-${orgCode}/0rg-metadata`;
    return this.ngFireDb.object(orgPath).valueChanges();
  }

  /**
   * Sign In / Login the user with email & password
   * @param email registered email
   * @param password secure password
   */
  public login(email: string, password: string): Promise<any> {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
    // return this.fbAuth.signInWithEmailAndPassword(email, password);
  }

  public validateSession(): void {
    this.ngFireAuth.currentUser.then((user) => user?.getIdToken(true));
  }

  public auditLog(): void {
    const now = new Date().toISOString();
    // TODO: Audit log the login attempt into /provider-users/${uid}/lastLogin with timestamp
    if (this.userAccount) {
      this.ngFireDb.object(`${this.usersPath}/${this.userAccount.uid}/logs`).update({ last_login: now });
    }
  }

  private signout() {
    // this.fbAuth
    this.ngFireAuth
      .signOut()
      .then(() => {
        console.log(`[AuditLog] logout(): Logout Finished!`);
        this.router.navigate(['/']);
      })
      .catch((err) => {
        console.log('AuthService: Error while logging out - ', err.message);
      });
  }
}
