import { Injectable } from '@angular/core';

/**
 * Service to be used App wide which serves useable defined constants
 * for named variables to be used with AppState
 * and for event names to be used to GlobalState
 */
@Injectable({
  providedIn: 'root',
})
export class AppVars {
  public static USER_ACCOUNT = 'USER_ACCOUNT';
  public static ORG_CODE = 'ORG_CODE';
  public static ORG_NAME = 'ORG_NAME';

  constructor() {}
}
